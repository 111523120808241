// Global
$andes-theme: 'mercadolibre';
// Andes
@import '~@andes/common/index';
@import '~@andes/common/secondary-palette';
// Andes components
@import '~@andes/card/index';
@import '~@andes/thumbnail/index';
@import '@andes/common/lib/styles/visually-hidden';
@import '../../../styles/variables';
@import '../../../styles/_fonts.scss';

// Quick Acsess
@import './components/categories-grid/categories-grid.mobile';
@import '../../commons/quick-access/components/quick-access-item/styles/index';
@import '../../commons/quick-access/quick-access-grid';

.categories {
  padding: 0 16px;
  background: $andes-gray-070-solid;
  overflow: auto;

  &__sections {
    .place__quick-access {
      .quick-access-item__label {
        width: 62px;
        display: inline-table;
      }
    }
    .place {
      margin-top: $andes-spacing-16;

      @media (orientation: landscape) {
        width: 440px;
        margin-left: auto;
        margin-right: auto;
      }

      &:last-child {
        margin-bottom: $andes-spacing-24;
      }

      &__title {
        color: $andes-text-color-primary;
        font-size: $font-size-16;
        line-height: 20px;
        margin: auto;
        margin-bottom: $andes-spacing-16;

        @media (orientation: landscape) {
          width: 440px;
        }
      }
    }

    .quick-access-bottom {
      margin-top: 24px;
    }
  }
}
