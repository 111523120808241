@import '../../../../commons/card/card-grid/card-grid';

.categories-card {
  display: flex;
  justify-content: center;

  &--grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, minmax(90px, 1fr));
    grid-gap: $andes-spacing-8;
    place-items: center;
    @media screen and (width <= 280px) {
      grid-template-columns: repeat(2, minmax(100px, 1fr));
    }
    @media (orientation: landscape) {
      width: 440px;
      grid-template-columns: repeat(4, minmax(100px, 1fr));
    }
  }
}
