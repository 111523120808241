.quick-access--grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 16px;
  place-items: center;
  align-items: flex-start;

  .quick-access-item {
    min-width: 55px;
    min-height: 66px;
  }

  .quick-access-item__label {
    hyphens: auto;
    overflow-wrap: break-word;
  }

  @media screen and (width <= 360px) {
    grid-template-columns: repeat(4, 1fr);
  }
}
